import React, { Component } from "react";
import { Link } from "gatsby";

export default class footer extends Component {
  render() {
    return (
      <div className="site-footer" id="footer">
        <div className="container">
          <p className="copyright">Copyright © 2021 Miquel Trallero</p>
          <a href="/privacy-policy" className="footer-text">Privacy Policy</a>          
          <span> | </span>
          <a href="/legal-notice" className="footer-text">Legal Notice</a>
          <span> | </span>
          <a href="/cookies-policy" className="footer-text">Cookies Policy</a>
          <span> | </span>
          <a href="/sitemap/sitemap-index.xml" className="footer-text">Sitemap</a>
        </div>
      </div>
    );
  }
}
