import React from "react";
import CookieConsent from "react-cookie-consent";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import "bootstrap/dist/css/bootstrap.css";

import Header from "./header";
import Footer from "./footer";

import "../css/style.css";
import "../css/font-awesome.css";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

const Layout = ({ children, header }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        contentfulSiteInformation {
          siteName
          siteDescription
          menus
        }
      }
    `}
    
    render={data => (
      <>
        <Header
          data={data.contentfulSiteInformation}
          siteTitle={data.contentfulSiteInformation.siteName}
          header={header}
        />
        <div>
          <main id="home">{children}</main>
        </div>
        <CookieConsent
          style={{justifyContent: "center", fontSize: "0.8rem"}}
          acceptOnScroll
          buttonStyle={{
            color: "#ef5826",
            fontSize: "1rem",
            background: "#fff",
            fontFamily: "inherit",
            borderRadius: 3,
            padding: 10,
            marginTop: 0        
          }}
          contentStyle={{flex: "0 0 100%", textAlign: "center"}}
        >
          <span>This website uses cookies to enhance the user experience.</span>
          <br/>
          <a href="/cookies-policy">Cookies Policy</a>
        </CookieConsent>
        <Footer siteName={data.contentfulSiteInformation.siteName} />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
